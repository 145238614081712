.progress {
  display: flex;
  height: 6px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
}

.fullWidth {
  width: 100%;
}

.center {
  border-radius: 4px;
}

.bottom {
  border-radius: 0px 0px 6px 6px;
}

.top {
  border-radius: 6px 6px 0px 0px;
}

.progressBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--white);
  text-align: center;
  white-space: nowrap;
  background-color: var(--blue);
  transition: width 0.6s ease;
  &-process {
    border-radius: 0 0.25rem 0.25rem 0;
  }
}

.progress {
  background-color: var(--blue15);
} 
