@import 'styles/layers.scss';

@layer component {
  .label {
    opacity: 0.3;
    margin-bottom: 6px;
    font-weight: 300;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .dropdown-toggle {
    padding: 12px;
    width: 484px;
    height: 37px;
    background: transparent;
    border: 1px solid var(--black28);
    font-size: 12px;
    &:hover {
      background: var(--black27);
    }
    &-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background: var(--black19);
      border: 1px solid var(--black19);
      &:hover {
        background: var(--black19);
      }
    }
    &-error {
      border: 1px solid var(--yellow);
    }
  }

  .dropdown-menu {
    width: 484px;
    padding: 10px 12px 5px;
    flex-direction: column;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    &:hover {
      background: var(--black19);
    }
    &::-webkit-scrollbar-track {
      background: inherit;
    }
  }

  .dropdown-menu-item {
    padding: 0;
    &:hover {
      background: var(--black19);
    }
    padding: 5px 0;
  }

  .icon-toggle {
    color: var(--grey6);
    &-open {
      color: var(--white);
    }
  }

  .group {
    row-gap: 15px;
  }

  .choosen {
    row-gap: 8px;
  }

  .checkbox-wrap {
    width: fit-content;
  }
}
