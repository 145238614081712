@import 'styles/layers.scss';

@layer uikit {
  $process-status-width: 100px;
  $process-status-mr-left: 10px;

  .item {
    border-radius: 8px;
    padding: 3px 0;
  }

  .processStatus {
    width: $process-status-width;
    margin-left: $process-status-mr-left;
    justify-content: flex-end;
  }

  .name {
    width: calc(100% - #{$process-status-width} - #{$process-status-mr-left});
    font-size: 12px;
  }

  .error {
    font-size: 14px;
    color: var(--red);
    margin-top: 5px;
  }

  .iconError {
    margin-right: 5px;
  }

  .message {
    flex: 1;
    overflow-wrap: anywhere;
  }
}