@import 'styles/layers.scss';

@layer uikit {
  .item {
    padding: 8px 15px 9px 15px;
    border-radius: 6px;
    width: 100%;
    background: rgba(12, 12, 12, 0.30); 
  }
  
  .title {
    flex: 1;
    overflow-wrap: anywhere;
    margin-right: 8px;
  }
  
  .iconClip {
    margin-right: 8px;
  }
  
  .fullWidth {
    width: 100%;
  }
}