@import 'styles/layers.scss';

@layer component {
  .balance {
    position: relative;
  }

  .text {
    color: var(--white);
    opacity: 0.5;
    padding: 2px 5px;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      opacity: 1;
      background-color: var(--black1);
      border-radius: 4px;
    }
  }

  .btn {
    width: 100px;
    height: 26px;
  }
}