@import 'styles/layers.scss';

@layer component {
  .mr {
    margin-right: 26px;
  }

  .icon-wrapper {
    margin-right: 38px;
    cursor: pointer;
  }
  
  .ml {
    margin-left: 26px;
  }

  .grey {
    color: var(--grey8);
    &:hover {
      color: var(--white);
    }
  }
}