@import 'styles/layers.scss';

@layer component {
  .btns {
    margin-top: 30px;
  }

  .btn {
    align-self: center;
  }

  .btnUpload {
    width: 101px;
    height: 41px;
  }

  .btnSaveAndClose {
    width: 158px;
    height: 41px;
  }
}
