@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    font-size: 12px;
  }

  .createdIcon {
    color: var(--green);
    margin-right: 7px;
  }

  .createdText {
    color: var(--green1);
  }

  .queueIcon {
    color: var(--grey7);
    margin-right: 7px;
  }

  .errorIcon {
    color: var(--red);
    margin-right: 7px;
  }

  .errorText {
    color: var(--red);
  }

  .queueText {
    color: var(--grey7);
  }

  .progressText {
    color: var(--white);
  }

  .spinner {
    width: 12px;
    height: 12px;
    border-width: 2px;
    margin-right: 7px;
  }

  .light {
    .queueText {
      color: var(--grey15);
    }
    .queueIcon {
      color: var(--grey15);
    }
  }
}