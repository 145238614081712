.root {
  border-radius: 4px;
  width: 100%;
  padding: 12px;
  word-break: break-word;
  font-size: 12px;
}

.warning {
  color: var(--orange1);
  border: 1px solid var(--orange1);
}

.error {
  color: var(--red);
  border: 1px solid var(--red);
  background-color: var(--red2);
}

.icon {
  margin: 3px 10px 0 0;
}
