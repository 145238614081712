.addFile {
  margin-left: 11px;
}

.file {
  width: 100%;
}

.addFile {
  color: var(--white);
}

.icon {
  color: var(--white);
}

.fullWidth {
  width: 100%;
}

.notification {
  margin-top: 6px;
}

.uploaderCoreContent {
  width: 100%;
}

.label {
  color: var(--white);
  opacity: 0.3;
  margin-bottom: 4px;
}