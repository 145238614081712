@import 'styles/layers.scss';

@layer uikit {
  .item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  
  .fullWidth {
    width: 100%;
  }
}